<template>
  <div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Catégorie</label>
        <select v-model="section_reference" class="form-select">
          <option
            v-for="(section, index) in sections"
            :key="index++"
            :value="section.reference"
          >
            {{ section.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Nom</label>

        <select v-model="item_price.item_reference" class="form-select">
          <option
            v-for="(item, index) in items.filter(
              (p) => p.section_reference == section_reference
            )"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Type de client</label>
        <select
          name=""
          id=""
          v-model="item_price.customer_type_reference"
          class="form-select"
        >
          <option value=""></option>
          <option
            v-for="(type, index) in customer_types"
            :key="index++"
            :value="type.reference"
          >
            {{ type.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Client</label>
        <select v-model="item_price.customer_reference" class="form-select">
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
          >
            {{ customer.fullName }}
          </option>
        </select>
      </div>

      <div class="col-2">
        <label for="">Prix</label>
        <input
          :disabled="false"
          v-model="item_price.price"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-auto">
        <button class="btn btn-secondary" @click="refresh()">
          <i class="bi bi-arrow-clockwise"></i> Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(item_price)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Désignation</th>
          <th
            v-for="(type, index) in customer_types"
            :key="index++"
            scope="col"
          >
            {{ type.fullName }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items.filter(
            (p) =>
              (p.section_reference == section_reference &&
                item_price.item_reference == '') ||
              (p.reference == item_price.item_reference &&
                item_price.item_reference != '')
          )"
          :key="index++"
        >
          <td>
            {{ index }}
          </td>

          <td>
            {{ item.fullName }}
          </td>
          <td v-for="(type, index) in customer_types" :key="index++">
            <span
              v-if="item.prices"
              :class="
                !item.prices.find(
                  (p) => p.customerType_reference == type.reference
                )
                  ? 'text-warning fw-normal'
                  : ' '
              "
              class=" "
            >
              {{
                item.prices.find(
                  (p) => p.customerType_reference == type.reference
                ) | price
              }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      item_price: {
        price: "",
      },
      section_reference: "",
    };
  },
  computed: {
    ...mapGetters("customer", {
      customer_types: "getCustomerTypes",
      customers: "getAll",
    }),
    ...mapGetters("item", {
      items: "getAll",
    }),

    ...mapGetters("section", {
      sections: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("item/storePrice", data);

      setTimeout(() => {
        this.item_price.price = "";
      }, 500);
    },
    async refresh() {
      this.$store.dispatch("customer/getCustomerTypes");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("section/getAll");

      this.$store.dispatch("item/getItemsPrices");
    },

    async empty() {
      setTimeout(() => {
        this.item_price = {};
      }, 1000);
    },
  },
  beforeMount() {
    this.$store.dispatch("customer/getCustomerTypes");
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("item/getAll");
    this.$store.dispatch("section/getAll");
    this.$store.dispatch("item/getItemsPrices");
  },
  filters: {
    price: function (value) {
      if (!value) return "X";
      return value.price;
    },
  },
};
</script>
